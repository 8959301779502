import { group } from 'd3'
import Vue from 'vue'
import Vuex from 'vuex'
import router from './routes/router';

import { postLogin, getLogin, postRegister, postForgotPassword, postResetPassword, getVersion, postCharge, postChargeEmail, postDepartment, postTestStat, postTime, postActivate, postClick } from "@/APIs";

import detail_topic from "./detail/topic.json";
import universities from "./detail/universities.json";
import data3 from './detail/data3.json'
import data1 from './detail/data1.json'
import data4 from './detail/data4.json'
import data2 from './detail/data2.json'
import data107 from './detail/data107.json'
import data7 from './detail/data7.json'
import data8 from './detail/data8.json'
import data9 from './detail/data9.json'
import data10 from './detail/data10.json'
import data11 from './detail/data11.json'
import data14 from './detail/data14.json'
import data17 from './detail/data17.json'
import data19 from './detail/data19.json'
import data24 from './detail/data24.json'
import data37 from './detail/data37.json'
import data28 from './detail/data28.json'
import data33 from './detail/data33.json'
import data39 from './detail/data39.json'
import data106 from './detail/data106.json'
import data43 from './detail/data43.json'
import data45 from './detail/data45.json'
import data46 from './detail/data46.json'
import data44 from './detail/data44.json'
import data47 from './detail/data47.json'
import data48 from './detail/data48.json'
import data50 from './detail/data50.json'
import data54 from './detail/data54.json'
import data55 from './detail/data55.json'
import data109 from './detail/data109.json'
import data51 from './detail/data51.json'
import data52 from './detail/data52.json'
import data56 from './detail/data56.json'
import data57 from './detail/data57.json'
import data61 from './detail/data61.json'
import data62 from './detail/data62.json'
import data64 from './detail/data64.json'
import data59 from './detail/data59.json'
import data63 from './detail/data63.json'
import data65 from './detail/data65.json'
import data66 from './detail/data66.json'
import data67 from './detail/data67.json'
import data68 from './detail/data68.json'
import data72 from './detail/data72.json'
import data69 from './detail/data69.json'
import data70 from './detail/data70.json'
import data73 from './detail/data73.json'
import data74 from './detail/data74.json'
import data75 from './detail/data75.json'
import data76 from './detail/data76.json'
import data78 from './detail/data78.json'
import data77 from './detail/data77.json'
import data79 from './detail/data79.json'
import data80 from './detail/data80.json'
import data82 from './detail/data82.json'
import data83 from './detail/data83.json'
import data84 from './detail/data84.json'
import data89 from './detail/data89.json'
import data90 from './detail/data90.json'
import data87 from './detail/data87.json'
import data88 from './detail/data88.json'
import data85 from './detail/data85.json'
import data94 from './detail/data94.json'
import data97 from './detail/data97.json'
import data110 from './detail/data110.json'
import data96 from './detail/data96.json'
import data102 from './detail/data102.json'
import data103 from './detail/data103.json'
import data104 from './detail/data104.json'
import data111 from './detail/data111.json'
import data112 from './detail/data112.json'
import data113 from './detail/data113.json'
import data114 from './detail/data114.json'
import data991 from './detail/data991.json'
import data992 from './detail/data992.json'
import data993 from './detail/data993.json'
import data994 from './detail/data994.json'
import data995 from './detail/data995.json'
import data996 from './detail/data996.json'
import data997 from './detail/data997.json'
import data998 from './detail/data998.json'
import data999 from './detail/data999.json'
import data2001 from './detail/data2001.json'
import data2003 from './detail/data2003.json'
import data2045 from './detail/data2045.json'
import data2046 from './detail/data2046.json'
import data2107 from './detail/data2107.json'
import data2108 from './detail/data2108.json'
import data2024 from './detail/data2024.json'
import data2037 from './detail/data2037.json'
import data2009 from './detail/data2009.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nextIndex: 0,
    token: null,
    token_forgot: "",
    not_paid_user: false,
    time1: null,
    time2: null,
    time_total: 1,
    time_remain_1: null,
    onLine: true,
    publicKey: `${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PUBLIC_KEY_REAL : process.env.VUE_APP_PUBLIC_KEY_TEST}`,
    have_ads: true,
    version_current: 4.37,
    version: null,
    // api: "/",
    api: `${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL : process.env.VUE_APP_URL_TEST}`,
    // api: "https://engenius.neighborsoft.com/",
    qr: "",
    // baseAPI: process.env.API || "http://localhost:3000",
    // api: "https://engenius.neighborsoft.com/",
    // api: "http://localhost/engenius/engenius/",
    // api: "http://localhost/korvor/",
    // baseAPI: "https://api.neighborsoft.com",
    // baseAPI: "http://localhost:3000",
    baseAPI: `${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL + '/api' : process.env.VUE_APP_API_TEST + '/api'}`,
    username: "",
    firstname: "",
    lastname: "",
    university: "",
    department: "",
    userPackage: {
      subject: [],
      exp: ''
    },
    new_exam_list: ['1', '37', '45', '8', '9', '107'],
    user_time_per_day: 0,

    checked_solve: true,

    arr1: [],
    arr1_index: null,
    arr2: [],
    arr2_index: null,
    arr3: [],
    arr3_index: null,
    arr4: [],
    arr4_index: null,
    arr5: [],
    arr5_index: null,
    arr6: [],
    arr6_index: null,

    realtest_topic: null,

    msgtype: "danger",
    msgAlert: "test",
    showAlert: false,

    startTime: "",
    endTime: "",

    currect: 0,
    click: 0,
    topic: [],
    topic_names: [{ text: 'หมวดพื้นฐาน', value: 0 }],
    department_names: [{ text: 'สาขา', value: 0 }],
    department_names_2: [{ text: 'สาขา', value: 0 }],
    topic_index: null,
    subject_names: [],
    subject_index: null,
    subject_current: "",
    first: true,
    // fab
    fabActions: [{
      name: "lightbulb",
      icon: "lightbulb",
      color: "#778899",
    },
    {
      name: "compress",
      icon: "compress",
      color: "#778899",
    },
    {
      name: "shuffle",
      icon: "shuffle",
      color: "#778899",
    },
    {
      name: "autorenew",
      icon: "autorenew",
      color: "#778899",
    },
    ],
    bgColor: "#778899",
    successColor: "green",
    position: "buttom-left",
    position2: "top-left",
    toggleWhenAway: false,
    fixedTooltip: true,

    randomQ: false,
    solve: false,
    solve1: false,
    items: [],
    // loading
    loading: false,
    number_exam: 10,

    goToTestNumber: false,

    impact: {
      round: 4,
      time: [0, 0, 0],
      totaltime: 0,
      score: [0, 0, 0],
      score_random: 0,
      score_test1: 0,
      score_test2: 0,
      group1: [2, 4, 10],
      group2: [3, 5, 8],
      exam1: [2, 4],
      exam2: [3, 5],
      // group1: [2, 4, 10, 14, 25, 53, 66, 71, 74, 89, 153, 155, 159, 165, 169, 221, 226, 230, 235, 237, 245, 248, 254, 258, 267, 269, 271, 274, 280, 282, 286, 288, 291],
      // group2: [3, 5, 8, 11, 15, 27, 54, 64, 70, 75, 87, 154, 156, 160, 166, 171, 223, 225, 231, 236, 238, 244, 249, 255, 259, 266, 272, 275, 281, 283, 285, 289, 292, 295, 337, 338, 343, 346, 350]
    },
    action: {
      answer: "", // correct, wrong 
      read_choice: "", // multi, one
      sort: "", // sort, random
      reset: "", // reset
      timestart: "",
      pagename: "",
    },
    subject_cover: [],


    // click: 0,
    // name_topic: "หมวดพื้นฐาน",
    // index_topic: 0,
    // index_subject: 1,
    // name_subject: "กลุ่มวิชาพื้นฐาน 1 วิชา : Engineering Materials",
    // api: "https://korvor.adulgun.com/"
    universities,
    detail_topic,
    data3,
    data1,
    data4,
    data2,
    data107,
    data7,
    data8,
    data9,
    data10,
    data11,
    data14,
    data17,
    data19,
    data24,
    data37,
    data28,
    data33,
    data39,
    data106,
    data43,
    data45,
    data46,
    data44,
    data47,
    data48,
    data50,
    data54,
    data55,
    data109,
    data51,
    data52,
    data56,
    data57,
    data61,
    data62,
    data64,
    data59,
    data63,
    data65,
    data66,
    data67,
    data68,
    data72,
    data69,
    data70,
    data73,
    data74,
    data75,
    data76,
    data78,
    data77,
    data79,
    data80,
    data82,
    data83,
    data84,
    data89,
    data90,
    data87,
    data88,
    data85,
    data94,
    data97,
    data110,
    data96,
    data102,
    data103,
    data104,
    data111,
    data112,
    data113,
    data114,
    data991,
    data992,
    data993,
    data994,
    data995,
    data996,
    data997,
    data998,
    data999,
    data2001,
    data2003,
    data2045,
    data2046,
    data2107,
    data2108,
    data2024,
    data2037,
    data2009,
    history: {
      subjectIndex: null,
      startDate: '',
      endDate: ''
    },
    realtest: {
      state: 0,
      testType: 0,
      testArray: [],
      testStatus: []
    }
  },
  mutations: {
    NEXT_PAGE(state, payload) {
      router.push({ name: payload.page });
    },
    ACTIVATE(state, payload) {
      postActivate(payload).then((res) => {
      }).catch(function (error) {
        // catch
      });
    },
    POST_DEPARTMENT(state, payload) {
      postDepartment(payload).then((res) => {
        // console.log(res.data.results);
        if (res.data.results) {
          location.reload();
        }
      }).catch(function (error) {
        // catch
      });
    },
    POST_OMISE(state, payload) {
      // console.log(state.publicKey);
      payload["amount"] = payload.price;
      // payload["email"] = payload.username;
      if (payload.promptpay) {
        payload["src"] = true
        payload["token"] = "promptpay"
        postCharge(payload, localStorage.token).then((res) => {
          // console.log(res.data);
          if (res.status === 200 && res.data.success) {
            state.qr = res.data.results.qr;
          } else {

          }
        })
          .catch(function (error) {
            // catch
          });
      } else {
        OmiseCard.open({
          publicKey: state.publicKey,
          image: "https://cdn.omise.co/assets/dashboard/images/omise-logo.png",
          locale: "th",
          submitLabel: "จ่าย",
          currency: "THB",
          // defaultPaymentMethod: "internet_banking",
          defaultPaymentMethod: "credit_card",
          // otherPaymentMethods: "credit_card,rabbit_linepay,truemoney",
          otherPaymentMethods: "internet_banking,rabbit_linepay,truemoney",
          amount: payload.price,
          submitFormTarget: "#checkout-form",
          frameLabel: "Package " + payload.name,
          onCreateTokenSuccess: (nonce) => {
            /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
            // console.log(nonce);
            payload["token"] = nonce
            if (nonce.startsWith("tokn_")) {
              payload["src"] = false;
              postCharge(payload, localStorage.token).then((res) => {
                // console.log(res.data);
                if (res.status === 200 && res.data.success) {
                  // state.qr = res.data.results.qr;
                  // console.log(res.data.results.authorize_uri);
                  window.location.href = res.data.results.authorize_uri;
                } else {

                }
              })
                .catch(function (error) {
                  // catch
                });
            } else {
              payload["src"] = true;
              postCharge(payload, localStorage.token).then((res) => {
                // console.log(res.data);
                if (res.status === 200 && res.data.success) {
                  // state.qr = res.data.results.qr;
                  // console.log(res.data.results.authorize_uri);
                  window.location.href = res.data.results.authorize_uri;
                } else {

                }
              })
                .catch(function (error) {
                  // catch
                });
            };
          },
          onFormClosed: () => {
            /* Handler on form closure. */
            // console.log("err")
          },
        });
      }
    },
    POST_OMISE_EMAIL(state, payload) {
      // console.log(state.publicKey);
      payload["amount"] = payload.price;
      // payload["email"] = payload.username;
      if (payload.promptpay) {
        payload["src"] = true
        payload["token"] = "promptpay"
        postChargeEmail(payload).then((res) => {
          // console.log(res.data);
          if (res.status === 200 && res.data.success) {
            state.qr = res.data.results.qr;
          } else {

          }
        })
          .catch(function (error) {
            // catch
          });
      } else {
        OmiseCard.open({
          publicKey: state.publicKey,
          image: "https://cdn.omise.co/assets/dashboard/images/omise-logo.png",
          locale: "th",
          submitLabel: "จ่าย",
          currency: "THB",
          // defaultPaymentMethod: "internet_banking",
          defaultPaymentMethod: "credit_card",
          // otherPaymentMethods: "credit_card,rabbit_linepay,truemoney",
          otherPaymentMethods: "internet_banking,rabbit_linepay,truemoney",
          amount: payload.price,
          submitFormTarget: "#checkout-form",
          frameLabel: "Package " + payload.name,
          onCreateTokenSuccess: (nonce) => {
            /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
            // console.log(nonce);
            payload["token"] = nonce
            if (nonce.startsWith("tokn_")) {
              payload["src"] = false;
              postChargeEmail(payload).then((res) => {
                // console.log(res.data);
                if (res.status === 200 && res.data.success) {
                  // state.qr = res.data.results.qr;
                  // console.log(res.data.results.authorize_uri);
                  window.location.href = res.data.results.authorize_uri;
                } else {

                }
              })
                .catch(function (error) {
                  // catch
                });
            } else {
              payload["src"] = true;
              postChargeEmail(payload).then((res) => {
                // console.log(res.data);
                if (res.status === 200 && res.data.success) {
                  // state.qr = res.data.results.qr;
                  // console.log(res.data.results.authorize_uri);
                  window.location.href = res.data.results.authorize_uri;
                } else {

                }
              })
                .catch(function (error) {
                  // catch
                });
            };
          },
          onFormClosed: () => {
            /* Handler on form closure. */
            // console.log("err")
          },
        });
      }
    },
    SET_HISTORY(state, payload) {
      this.commit("GET_VERSION");
      state.history = payload
    },
    SET_REAL_TEST_STATE(state, payload) {
      state.realtest.state = payload.state
    },
    SET_REAL_TEST_TEST_TYPE(state, payload) {
      state.realtest.testType = payload.testType
    },
    SET_REAL_TEST_TEST_ARRAY(state, payload) {
      state.realtest.testArray = payload.testArray
    },
    SET_REAL_TEST_TEST_STATUS(state, payload) {
      state.realtest.testStatus = payload.testStatus
    },
    SET_REAL_TEST_TEST_STATUS_INDEX(state, payload) {
      state.realtest.testStatus[payload.index] = payload.testStatus
    },
    SHOW_ALERT(state, payload) {
      state.msgtype = payload.type;
      state.msgAlert = payload.msg;
      state.showAlert = true;
    },
    GET_VERSION(state, payload) {
      getVersion().then((res) => {
        state.version = res.data.results.version
      }).catch(function (error) {
      });
    },
    REGISTER(state, payload) {
      var e = this;
      // console.log(payload);
      postRegister(payload)
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200 && res.data.success) {
            if (res.data.results.msg == "Success") {
              this.commit("SHOW_ALERT", { msg: "สมัครสำเร็จ", type: "success" });
              router.push({ name: "login" });
            } else if (res.data.results.msg == "Username is unavialible") {
              this.commit("SHOW_ALERT", { msg: "Email นี้สมัครเรียบร้อยแล้ว กรุณาใช้ email อื่น", type: "danger" });
            } else {
              this.commit("SHOW_ALERT", { msg: "สมัครไม่สำเร็จ", type: "danger" });
            }
          } else {
            this.commit("SHOW_ALERT", { msg: "สมัครไม่สำเร็จ", type: "danger" });
          }
        })
        .catch(function (error) {
          // catch
        });
    },
    FORGOTPASSWORD(state, payload) {
      var e = this;
      // console.log(payload);
      postForgotPassword(payload)
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200 && res.data.success && res.data.results.token != "error") {
            // this.commit("SHOW_ALERT", { msg: "กรุณาตรวสอบอีเมลล์ (ทั้งหมด/ถังขยะ)", type: "success" });
            state.token_forgot = res.data.results.token;
          } else {
            state.token_forgot = "";
            this.commit("SHOW_ALERT", { msg: "ข้อมูลไม่ถูกต้อง", type: "danger" });
          }
        })
        .catch(function (error) {
          // catch
        });
    },
    RESETPASSWORD(state, payload) {
      postResetPassword(payload)
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200 && res.data.success) {
            this.commit("SHOW_ALERT", { msg: "อัปเดทรหัสผ่านเรียบร้อย", type: "success" });
            state.token_forgot = "";
            router.push({ name: "login" });
          } else {
            this.commit("SHOW_ALERT", { msg: "เกิดข้อผิดพลาด", type: "danger" });
          }
        })
        .catch(function (error) {
          // catch
        });
    },
    LOGIN(state, payload) {
      // console.log(payload);
      const token = Buffer.from(`${payload.username}:${payload.password}`, 'utf8').toString('base64')
      var e = this;
      postLogin(token)
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            if (res.data.results.token) {
              localStorage.token = res.data.results.token;
              state.token = res.data.results.token;
              this.commit("SHOW_ALERT", { msg: "เข้าสู่ระบบสำเร็จ", type: "success" });
              this.commit("AUTHEN");
            } else {
              this.commit("SHOW_ALERT", { msg: "เข้าสู่ระบบไม่สำเร็จ", type: "danger" });
            }
          } else {
            this.commit("SHOW_ALERT", { msg: "เข้าสู่ระบบไม่สำเร็จ", type: "danger" });
          }
        })
        .catch(function (error) {
          // catch
        });
    },
    LOGOUT(state, payload) {
      state.username = "";
      // this.commit("SET_TOKEN", "");
      localStorage.token = "";
      this.commit("AUTHEN");
      router.replace({ name: "login" });
    },
    POST_TIME(state) {
      postTime({}, localStorage.token)
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            state.user_time_per_day = res.data.results.time;
          }
        })
        .catch(function (error) {
          // catch
        });
    },
    POST_CLICK(state, payload) {
      // console.log(payload);
      postClick(payload)
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            // console.log(res.data.results);
          }
        })
        .catch(function (error) {
          // catch
        });
    },
    AUTHEN(state, payload) {
      this.commit("GET_VERSION");
      var token = "";
      token = localStorage.token;
      // console.log(token);
      getLogin(token).then((res) => {
        if (res.status === 200 && res.data.success) {
          var data = res.data.results;
          state.username = data.user_id;
          state.firstname = data.firstname;
          state.lastname = data.lastname;
          state.university = data.university;
          state.department = data.department;
          state.userPackage.subject = data.subject ? data.subject : []
          state.userPackage.exp = data.exp ? data.exp : ''
          state.user_time_per_day = data.time
          state.not_paid_user = state.userPackage.exp
            ? moment(state.userPackage.exp).isAfter(moment())
            : false;
          // console.log(state.not_paid_user)
          // setTimeout(function () {
          //   this.commit("GEN_REALTEST");
          // }, 1000);
          // this.commit("RELOAD_SUBJECT")
          var current_router = router.history.current.name;
          if (current_router == "login") {
            router.push({ name: "โปรไฟล์" });
          }
        } else {
          if (current_router != "login") {
            // router.replace({ name: "login" });
          }
        }
      }).catch(function (error) {
        // catch
      });
    },
    GEN_REALTEST(state, payload) {
      // var department = state.department;
      var department = payload.department;
      // console.log(department)
      if (department >= 0) {
        var arr1, arr2, arr3, arr4, arr5, arr6;
        if (department == 0) {
          arr1 = ["Theory of Structures and Structural Analysis"];
          arr2 = ["Reinforced Concrete Design"];
          arr3 = ["Timber and Steel Design"];
          arr4 = ["Soil Mechanics"];
          arr5 = [
            "Highway Engineering",
            "Hydraulic Engineering",
            "Route Surveying",
            "Construction and Management",
          ];
        }
        if (department == 1) {
          arr1 = ["Mechanics of Machinery"];
          arr2 = ["Machine Design"];
          arr3 = ["Heat Transfer"];
          arr4 = [
            "Automatic Control",
            "Internal Combustion Engines",
            "Refrigeration and Air Conditioning",
            "Power Plant Engineering",
          ];
        }
        if (department == 2) {
          arr1 = ["Electrical Machines"];
          arr2 = ["Electrical System Design"];
          arr3 = ["Electric Power System Analysis"];
          arr4 = [
            "Electrical Instruments and Measurements",
            "Power Electronics",
            "High Voltage Engineering",
            "Protection and Relay",
          ];
        }
        if (department == 3) {
          arr1 = ["Principles of Communications"];
          arr2 = ["Data Communication and Network"];
          arr3 = ["Digital Communication"];
          arr4 = [
            "Electrical Instruments and Measurements",
            "Optical Communication",
            "Microwave Engineering",
            "Antenna Engineering",
          ];
        }
        if (department == 4) {
          arr1 = ["Safety Engineering"];
          arr2 = ["Production Planning and Control"];
          arr3 = ["Quality Control"];
          arr4 = [
            "Industrial Plant Design",
            "Industrial Work Study",
            "Engineering Economy",
            "Maintenance Engineering",
          ];
        }
        if (department == 5) {
          arr1 = ["Surface Mining and Mine Design"];
          arr2 = ["Underground Mining and Mine Design"];
          arr3 = ["Mineral Processing I"];
          arr4 = [
            "Mine Economics",
            "Geotechniques",
            "Mineral Processing II",
            "Mine Planning and Design",
          ];
        }
        if (department == 6) {
          arr1 = ["Chemical Metallurgy"];
          arr2 = ["Physical Metallurgy"];
          arr3 = ["Mechanical Behaviour of Materials"];
          arr4 = [
            "Materials Characterization",
            "Metal Forming",
            "Corrosion of Metals",
            "Failure analysis",
          ];
        }
        if (department == 7) {
          arr1 = [
            "Water Supply Engineering  Design  and Advanced Water Treatment",
          ];
          arr2 = [
            "Wastewater Engineering  Design  Industrial Water Pollution  and Advanced Wastewater Treatment",
          ];
          arr3 = ["Solid Waste Engineering"];
          arr4 = [
            "Air Pollution Control and Design",
            "Hazardous Waste Engineering",
            "Building Sanitation",
            "Noise and Vibration Control",
          ];
        }
        if (department == 8) {
          arr1 = ["Fluid Flow"];
          arr2 = ["Heat Transfer and Mass Transfer"];
          arr3 = ["Chemical Engineering Kinetics and Reactor Design"];
          arr4 = [
            "Chemical Engineering Thermodynamics",
            "Process Dynamics and Control",
            "Chemical Engineering Plant Design",
            "Safety in Chemical Operations",
            "Environmental Chemical Engineering",
          ];
        }
        if (department == 10) {
          arr1 = ["สิ่งแวดล้อม"];
          arr2 = ["จรรยาบรรณ"];
          arr3 = ["ความปลอดภัย"];
          arr4 = ["กฎหมาย"];
        }
        // if (department == 10) {
        //   arr1 = ["กลศาสตร์ของไหล (Fluid Power Control)"];
        //   arr2 = ["วัสดุวิศวกรรม (Engineering Materials)"];
        //   arr3 = ["การออกแบบเครื่องกล (Machine Design)"];
        //   arr4 = ["การปรับอากาศและการระบายความร้อน (Refrigeration and Air Conditioning)"];
        //   arr5 = ["ระบบยานยนต์ (Dynamics of Vehicles)"];
        //   arr6 = ["อุณหพลศาสตร์ (Thermal System Design)"];
        // }
        //
        const int_department = parseInt(department);
        var topic = state.detail_topic[int_department < 10? int_department + 1: int_department]["list"];
        // console.log(topic)
        state.arr1 = [];
        state.arr2 = [];
        state.arr3 = [];
        state.arr4 = [];
        if (department == 0) {
          state.arr5 = [];
        }
        if (department == 9) {
          state.arr5 = [];
          state.arr6 = [];
        }

        for (let i = 0; i < topic.length; i++) {
          const obj = topic[i]
          const e = topic[i].subject;
          for (let j = 0; j < arr1.length; j++)
            if (e == arr1[j]) state.arr1.push(obj);
          for (let j = 0; j < arr2.length; j++)
            if (e == arr2[j]) state.arr2.push(obj);
          for (let j = 0; j < arr3.length; j++)
            if (e == arr3[j]) state.arr3.push(obj);
          for (let j = 0; j < arr4.length; j++)
            if (e == arr4[j]) state.arr4.push(obj);
          // 
          if (department == 0) {
            for (let j = 0; j < arr5.length; j++)
              if (e == arr5[j]) state.arr5.push(obj);
          }
          if (department == 9) {
            for (let j = 0; j < arr5.length; j++)
              if (e == arr5[j]) state.arr5.push(obj);
            for (let j = 0; j < arr6.length; j++)
              if (e == arr6[j]) state.arr6.push(obj);
          }

        }
        // console.log(department)
        // console.log(state.arr1);
        // console.log(state.arr2);
        // console.log(state.arr3);
        // console.log(state.arr4);
        state.arr1_index = state.arr1[0].index;
        state.arr2_index = state.arr2[0].index;
        state.arr3_index = state.arr3[0].index;
        state.arr4_index = state.arr4[0].index;
         if (department == 0) {
          state.arr5_index = state.arr5[0].index;
        }
        if (department == 9) {
          state.arr5_index = state.arr5[0].index;
          state.arr6_index = state.arr6[0].index;
        }
        // console.log(state.arr1_index);
        // console.log(state.arr2_index);
        // console.log(state.arr3_index);
        // console.log(state.arr4_index);
      }
    },
    RELOAD_SUBJECT(state) {
      state.loading = true;
      var e = this;
      setTimeout(function () {
        if (state.username)
          state.subject_index = localStorage.subject_index;
        e.commit("FETCH_DATA");
        state.currect = 0;
        state.click = 0;
        state.startTime = new Date();
      }, 1);
    },
    FETCH_DATA(state) {
      var index = state.subject_index;
      if (index) {
        var d = state["data" + index][0];
        this.commit("PUSH_DATA", { "d": d })
        //   this.scrollToTop();
        state.subject_current = d.subject;
      }
    },
    PUSH_DATA(state, payload) {
      var temp = [];
      state.items = [];
      var d = payload["d"];
      var len = d.ans.length;
      // console.log(state.username);
      for (let i = 0; i < len; i++) {
        temp.push({
          id: i,
          question: d.question[i],
          ans: d.ans[i],
          choice: d.choice[i],
          done: -1,
          tag: parseInt(d.tag ? d.tag[i] || 0 : 0),
        });
      }
      // if (state.username) {
      //     for (let i = 0; i < len; i++) {
      //         temp.push({
      //             id: i,
      //             question: d.question[i],
      //             ans: d.ans[i],
      //             choice: d.choice[i],
      //             done: -1,
      //         });
      //     }
      // } else {
      //     for (let i = 0; i < len; i++) {
      //         if (i == state.number_exam / 2) {
      //             i = len - state.number_exam / 2;
      //         }
      //         temp.push({
      //             id: i,
      //             question: d.question[i],
      //             ans: d.ans[i],
      //             choice: d.choice[i],
      //             done: -1,
      //         });
      //     }
      // }

      state.items = temp;
      state.loading = false;
      this.commit("SHUFFLE_CHECK")
      // state.shuffle_check();

      // push solution
      state.subject_cover = [];
      // console.log(state.subject_cover);
      // console.log(state.items);
      var token = localStorage.token;
      for (let i = 0; i < state.items.length; i++) {
        const item = state.items[i];
        var url =
          state.baseAPI +
          "/subjects/solve/" +
          state.subject_index +
          "/" +
          (item.id + 1) +
          "/" +
          token;
        // console.log(url);
        state.subject_cover.push(url);
      }
      // console.log(state.subject_cover);
    },
    SHUFFLE_CHECK(state) {
      state.currect = 0;
      state.click = 0;
      // for (let i = 0; i < this.info.length; i++) {
      //     this.info[i].done = -1;
      //     this.info[i].done = -1;
      // }
      if (state.randomQ) {
        // this.RANDOM_LIST(this.items);
        state.items.sort(function () {
          return 0.5 - Math.random();
        });
      } else {
        // this.SORT_LIST(this.items);
        
        if (state.new_exam_list.includes(state.subject_index)) {
          state.items.sort(function (a, b) {
            return b.id - a.id;
          });
        } else {
          state.items.sort(function (a, b) {
            return a.id - b.id;
          });
        }
      }
    },
    LIGHTBULB(state) {
      state.solve = !state.solve;
      if (state.solve == false) state.solve1 = false;
      this.commit("CHANGE_COLOR")
    },
    COMPRESS(state) {
      state.solve1 = !state.solve1;
      state.solve = state.solve1;
      this.commit("CHANGE_COLOR")
    },
    SHUFFLE(state) {
      state.randomQ = !state.randomQ;
      this.commit("SHUFFLE_CHECK")
      this.commit("AUTO_RENEW")
      this.commit("CHANGE_COLOR")
    },
    AUTO_RENEW(state) {
      this.commit("RELOAD_SUBJECT")
    },
    CHANGE_COLOR(state) {
      if (state.solve) state.fabActions[0].color = state.successColor;
      else state.fabActions[0].color = state.bgColor;
      if (state.solve1) state.fabActions[1].color = state.successColor;
      else state.fabActions[1].color = state.bgColor;
      if (state.randomQ) state.fabActions[2].color = state.successColor;
      else state.fabActions[2].color = state.bgColor;
    },
    SHOW_RESULT(state, payload) {
      var number = payload.number;
      var done = payload.done
      state.endTime = new Date();
      var diff = Math.abs(Math.round((state.endTime - state.startTime) / 1000)); // diff time (sec)
      state.items[number].done = done + 1;
      if (state.items[number].done == state.items[number].ans)
        state.currect++;
      state.click++;
      var params = {
        username: state.username,
        subjectIndex: state.subject_index,
        startTime: state.startTime,
        endTime: state.endTime,
        time: diff,
        numberIndex: parseInt(state.items[number].id),
        respondIndex: parseInt(done),
        answer: parseInt(state.items[number].ans),
        result: parseInt(state.items[number].done) ===
          parseInt(state.items[number].ans),
      };
      if (state.username) {
        localStorage.test_number = number;
        postTestStat(params)
          .then((res) => {
            if (res.status === 200 && res.data.success) { }
          })
          .catch(function (error) {
            // catch
          });
      }
      state.startTime = new Date();
      this.commit("GET_VERSION");
    },
  },
  actions: {

  }
})